
require("./google_analytics")
require("../vendor/bracket/lib/datatables.net/js/jquery.dataTables.min")
//require("../vendor/bracket/lib/datatables.net-dt/js/dataTables.dataTables.min")
//require("../vendor/bracket/lib/datatables.net-responsive/js/dataTables.responsive.min")
//require("../vendor/bracket/lib/datatables.net-responsive-dt/js/responsive.dataTables.min")

// BRACKET TEMPLATE JS
require("../vendor/bracket/lib/jquery-ui/ui/widgets/datepicker")
//import "../vendor/bracket/lib/bootstrap/js/bootstrap.bundle.min";
require("../vendor/bracket/lib/perfect-scrollbar/perfect-scrollbar.min")
//import "../vendor/bracket/lib/moment/min/moment.min";
//import "../vendor/bracket/lib/peity/jquery.peity.min";
//import "../vendor/bracket/lib/rickshaw/vendor/d3.min;";
//import "../vendor/bracket/lib/rickshaw/vendor/d3.layout.min";
//import "../vendor/bracket/lib/rickshaw/rickshaw.min";
//import "../vendor/bracket/lib/jquery.flot/jquery.flot";
//import "../vendor/bracket/lib/jquery.flot/jquery.flot.resize";
//import "../vendor/bracket/lib/flot-spline/js/jquery.flot.spline.min";
//import "../vendor/bracket/lib/jquery-sparkline/jquery.sparkline.min";
//import "../vendor/bracket/lib/echarts/echarts.min";
import "../vendor/bracket/lib/select2/js/select2.full.min";
//import "../vendor/bracket/lib/intl-tel-input/build/js/intlTelInput.js";
import "../vendor/bracket/lib/intl-tel-input/build/js/intlTelInput-jquery.min";
import "../vendor/bracket/lib/cookieconsent/dist/cookieconsent";



require("../vendor/bracket/js/bracket")
require("../vendor/bracket/js/ResizeSensor")
require("../vendor/bracket/js/dashboard")

require("./init_datatables")
require("./init_select2")
require("./init_intl_tel_input")
require("./social-share-button")
require("./cookieconsent_init")


